import { OPTION_INTER } from '../../data/user/constant';
import { FORM_VALUE_INTER } from './constant';

const toBoolean = (value: string | null | undefined): boolean | undefined => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return undefined;
};

export const convert = (values: any) => {
  try {
    return {
      ...values,
      gender: typeof values?.gender != 'object' ? values.gender : null,
      timezone: Number(values?.timezone),
      language: values?.language.map((e: OPTION_INTER) => e.value),
      specialistNeeded: values?.specialistNeeded?.map(
        (e: OPTION_INTER) => e.value,
      ),
      // needCompatibility: toBoolean(values?.needCompatibility),
      // compatibilityAnimal: toBoolean(values?.compatibilityAnimal),
      // vaccination: values?.vaccination?.map((e: OPTION_INTER) => e.value),
      age: values?.age === '' ? null : values.age,
    };
  } catch (err) {
    console.log('ERR: ', err);
  }
};
