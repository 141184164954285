import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { useDispatch } from '../../lib/store';
import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
} from './constant';
import { Component } from './component';
import { FormikValues, useFormik } from 'formik';
import { validation } from '../../lib/validation';
import { action as fetch, getAction } from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select';
import { required, requiredArray } from '../../lib/validation/service';
import { convertPsychologist } from '../../data/user/convert';
import {
  PSYCHOLOGIST_ITEM_DATA_INTER,
  PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
} from '../../data/user/constant';
import { convert } from './convert';

const config = {
  [FORM_VALUE_ENUM.ANIMAL_TYPE_LIST]: [required, requiredArray],
  [FORM_VALUE_ENUM.SPECIALIST_LIST]: [required, requiredArray],
  [FORM_VALUE_ENUM.TRANSPORTATION_LIST]: [required, requiredArray],
  [FORM_VALUE_ENUM.PLACEMENT_LIST]: [required, requiredArray],
  [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]: [required],
};

const validate = (values: FormikValues) => validation(values, config);

export const Container: React.FC = () => {
  const query = useQueryClient();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<any>({});

  const action = useMutation(
    (d: PSYCHOLOGIST_ITEM_DATA_RAW_INTER) => dispatch(fetch(d)),
    {
      onSuccess: () => {
        return query.invalidateQueries(MODULE_NAME);
      },
    },
  );

  const preFetch = useQuery(MODULE_NAME, () => getAction());

  const getData = async (): Promise<
    PSYCHOLOGIST_ITEM_DATA_INTER | undefined
  > => {
    const data =
      (await preFetch.data) as unknown as PSYCHOLOGIST_ITEM_DATA_RAW_INTER;

    if (data) {
      return convertPsychologist(data);
    }
  };

  const fetchData = async () => {
    if (preFetch.data) {
      const data = await getData();

      if (preFetch.isSuccess && data) {
        setInitialValues({
          [FORM_VALUE_ENUM.ANIMAL_TYPE_LIST]: data.workAnimalType || [],
          [FORM_VALUE_ENUM.SPECIALIST_LIST]: data.specialistWith || [],
          [FORM_VALUE_ENUM.TRANSPORTATION_LIST]: data.transportation || [],
          [FORM_VALUE_ENUM.PLACEMENT_LIST]: data.placement || [],
          [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]:
            data.ableCompatibilityAnimal || '',

          [FORM_VALUE_ENUM.ALLOWS_WEIGHT_MORE_THIRTY]:
            data.allowsWeightMoreThirty,
          [FORM_VALUE_ENUM.ALLOWS_AGE_MORE_EIGHT]: data.allowsAgeMoreEight,
          [FORM_VALUE_ENUM.ALLOWS_AGGRESSIVE]: data.allowsAggressive,
          [FORM_VALUE_ENUM.ALLOWS_NAUGHTY]: data.allowsNaughty,
          [FORM_VALUE_ENUM.ALLOWS_BITE]: data.allowsBite,
          [FORM_VALUE_ENUM.ALLOWS_PHYSICAL_DISABILITY]:
            data.allowsPhysicaldisability,
          [FORM_VALUE_ENUM.ALLOWS_UNATTENDED_ANIMAL]:
            data.allowsUnattendedAnimal,
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
    if (action.isSuccess) {
      formik.resetForm();
    }
  }, [preFetch.isSuccess, action.isSuccess]);

  const formik: FormikValues = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      const convertedValues = convert(values);

      return action.mutate(convertedValues);
    },
  });

  // useEffect(() => {
  //   formik.setValues(initialValues); // почему-то по другому формик не инициализирует значения, даже если data есть // переделать с использованием enableReinitialize
  // }, [initialValues]);

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading || preFetch.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
  };

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const preFetchError: ACTION_ERROR_INTER =
      action.error as ACTION_ERROR_INTER;

    if (error) {
      return error.message;
    }
    if (preFetchError) {
      return preFetchError.message;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    if (typeof formik.values[value] != 'object') {
      const optionList = options?.filter(
        (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
      )[0];
      return optionList ? optionList : null;
    }
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) =>
        item.value == formik.values[value]?.value,
    )[0];
    return optionList ? optionList : null;
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  return (
    <Component
      onChangeSelect={onChangeSelect}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoadingAction={action.isLoading}
      isSuccessAction={action.isSuccess}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
    />
  );
};
