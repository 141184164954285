import { OPTION_INTER } from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';
// export const MODULE_DATA_NAME = 'PROFILE_PSYCHO_DATA_MODULE_NAME'; // На перенос в соответсвующий модуль
export const MODULE_NAME_PHOTO = 'PROFILE_PSYCHO_UPDATE_PHOTO_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  PHONE = 'phone',
  NAME = 'name',
  AGE = 'age',
  GENDER = 'gender',
  TIMEZONE = 'timezone',
  CITY = 'city',
  COUNTRY = 'country',
  LANGUAGE = 'language',
  ISFOP = 'isFop',
  BANK_NUMBER = 'bankNumber',
  BANK_NAME = 'bankName',
  DISTRICT = 'district',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.NAME]: string;
  [FORM_VALUE_ENUM.PHONE]: string;
  [FORM_VALUE_ENUM.AGE]: number | null;
  [FORM_VALUE_ENUM.GENDER]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.TIMEZONE]: string | number;
  [FORM_VALUE_ENUM.LANGUAGE]: OPTION_INTER[];
  [FORM_VALUE_ENUM.CITY]: string;
  [FORM_VALUE_ENUM.COUNTRY]: string;
  [FORM_VALUE_ENUM.ISFOP]: string | null;
  [FORM_VALUE_ENUM.BANK_NAME]: string;
  [FORM_VALUE_ENUM.DISTRICT]: string;
  [FORM_VALUE_ENUM.BANK_NUMBER]: number;
}

export const GENDER_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.MALE'),
    value: 1,
  },
  {
    label: i18n.t('USER.USER.SELECT.FEMALE'),
    value: 2,
  },
];
export const ISFOP_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.YES'),
    value: true,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.NO'),
    value: false,
  },
];
export const YES_NO_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.YES'),
    value: 'true',
  },
  {
    label: i18n.t('USER.USER.SELECT.NO'),
    value: 'false',
  },
];

export const LANGUAGE_OPTION_LIST = [
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.UA'),
    value: 'UA',
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.ENG'),
    value: 'ENG',
  },
  {
    label: i18n.t('USER.USER.SELECT.LANGUAGE.POL'),
    value: 'POL',
  },
];

export const API = {
  MAIN: {
    TYPE: 'PATCH',
    URL: 'user/data-update',
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/psychologist-data',
  },
  PHOTO: {
    POST: {
      TYPE: 'POST',
      URL: 'user/photo',
    },
    DELETE: {
      TYPE: 'DELETE',
      URL: (id: string | null) => `file/${id}`,
    },
    GET: {
      TYPE: 'GET',
      URL: 'user/psychologist-file',
    },
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
