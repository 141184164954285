import styled from 'styled-components';
import { COLOR_ENUM } from '../../../theme/color';
import { Spacing } from '../../../theme';
import { TextElem } from '../../../common/text';
import { USER_ROLE } from '../../../data/auth/constant';
import { useHistory } from 'react-router';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../../theme/size';
import { CONSULTATION_DATA_INTER } from '../../../data/consultation/constant';
import { ButtonElem } from '../../../common/button';
import userPhoto from '../../../asset/svg/user/user-photo.svg';
import buttonArrow from '../../../asset/svg/button/arrow-right.svg';
import { FlexElem } from '../../../common/flex';
import { i18n } from '../../../lib/lang';
import { getYearLabel } from '../../../lib/util/yearConvert';
// import { getHourLabel } from '../../../lib/util/hourConvert';

export const UserHeader: React.FC<
  Partial<CONSULTATION_DATA_INTER & { role: USER_ROLE }>
> = ({ user, psychologist, role }) => {
  const history = useHistory();

  const name = role === USER_ROLE.CARETAKER ? user?.name : psychologist?.name;
  const photo =
    role === USER_ROLE.CARETAKER ? user?.photo?.url : psychologist?.photo?.url;

  const handleRedirect = (id?: string) => {
    history.push(`/specialist/${id}`);
  };

  return (
    <Block>
      <Profile>
        <Photo alt="profile photo" src={photo || userPhoto} />
        <Info>
          <Title
            tid={name ?? 'COMMON.NO_NAME'}
            size="main"
            type="medium"
            color="textPrimary"
          />
          <FlexElem spacing={2}>
            <Description
              tid={
                role === USER_ROLE.USER
                  ? 'CONSULTATION.PSYCHOLOGIST.ROLE'
                  : 'CONSULTATION.USER.ROLE'
              }
              size="semiSmall"
              color="textSecondary"
            />
            {psychologist?.yearExperience && (
              <>
                <Dot className="info" />
                <Description
                  className="info"
                  type="regular"
                  color="textSecondary"
                  size="semiSmall"
                  oneLine
                >
                  <Description
                    type="medium"
                    color="textPrimary"
                    size="semiSmall"
                    tid={getYearLabel(psychologist.yearExperience) ?? undefined}
                  />
                  {i18n.t('CONSULTATION.USER.YEAR_EXPERIENCE')}
                </Description>
              </>
            )}

            {/* {psychologist?.hourPractice && (
              <>
                <Dot className="info" />
                <Description
                  className="info"
                  type="regular"
                  color="textSecondary"
                  size="semiSmall"
                  oneLine
                >
                  <Description
                    type="medium"
                    color="textPrimary"
                    tid={getHourLabel(psychologist.hourPractice) ?? undefined}
                  />
                  {i18n.t('CONSULTATION.USER.HOUR_PRACTICE')}
                </Description>
              </>
            )} */}
          </FlexElem>
        </Info>
      </Profile>
      {role === USER_ROLE.USER && (
        <InfoContainer>
          <TextInfo
            type="regular"
            color="textSecondary"
            size="semiSmall"
            tid="USER.PSYCHO.PROFILE.NAME"
            tvalue={{
              age: psychologist?.age && `${psychologist?.age}`,
              gender: psychologist?.gender && `• ${psychologist?.gender}`,
              timezone: psychologist?.timezone && `• ${psychologist?.timezone}`,
            }}
          />

          <ButtonStyled type="button" onClick={() => handleRedirect('')}>
            <ButtonText
              size="main"
              type="medium"
              color="default"
              tid="CONSULTATION.USER.PROFILE_BUTTON"
            />
            <img src={buttonArrow} alt="arrow" />
          </ButtonStyled>
        </InfoContainer>
      )}
    </Block>
  );
};

const Dot = styled.div`
  margin: auto 0;
  width: 4px;
  height: 4px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.TEXT_SECONDARY]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
`;

const ButtonText = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    display: none;
  }
`;

const Title = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 15px;
  }
`;

const Description = styled(TextElem)`
  @media screen and (max-width: 1160px) {
    font-size: 12px;
  }
`;

const TextInfo = styled(TextElem)`
  display: none;
  @media screen and (max-width: 1110px) {
    display: none;
  }
`;

const InfoContainer = styled.div`
  gap: ${Spacing(5)};
  align-items: center;
  display: flex;
  white-space: nowrap;
  justify-content: end;
  width: 100%;
`;

const ButtonStyled = styled(ButtonElem)`
  display: flex;
  gap: ${Spacing(2)};
  padding: ${Spacing(4)};
  border-radius: ${Spacing(2)};
  max-width: ${Spacing(47)};

  span {
    font-size: 14px;
  }

  @media screen and (max-width: 1160px) {
    min-width: 42px;
    max-width: 42px;
    height: 42px;
    padding: unset;
    align-items: center;
    justify-content: center;
  }
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (max-width: 768px) {
    .info {
      display: none;
    }
  }
`;

const Photo = styled.img`
  width: ${Spacing(16)};
  height: ${Spacing(16)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  object-fit: cover;

  @media screen and (max-width: 1160px) {
    min-width: ${Spacing(14)};

    height: ${Spacing(14)};
  }
`;

const Block = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(4)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.MAIN]}px;
  margin-bottom: ${Spacing(2)};
  height: fit-content;

  @media screen and (max-width: 1160px) {
    padding: ${Spacing(3)};
    margin-bottom: ${Spacing(1)};
  }
`;

const Profile = styled.div`
  display: flex;
  gap: ${Spacing(4)};
  align-items: center;
  width: 100%;
`;
