import settingIcon from '../../asset/svg/menu-app/setting.svg';
import settingActiveIcon from '../../asset/svg/menu-app/setting-active.svg';
import userIcon from '../../asset/svg/menu-app/user.svg';
import userActiveIcon from '../../asset/svg/menu-app/user-active.svg';
import paymentIcon from '../../asset/svg/menu-app/payment.svg';
import paymentActiveIcon from '../../asset/svg/menu-app/payment-active.svg';
import accountIcon from '../../asset/svg/menu-app/account.svg';
import accountActiveIcon from '../../asset/svg/menu-app/account-active.svg';
import noteIcon from '../../asset/svg/menu-app/note.svg';
import noteActiveIcon from '../../asset/svg/menu-app/note-active.svg';
import calendarIcon from '../../asset/svg/menu-app/calendar.svg';
import calendarActiveIcon from '../../asset/svg/menu-app/calendar-active.svg';
import psychoIcon from '../../asset/svg/menu-app/psycho-list.svg';
import psychoActiveIcon from '../../asset/svg/menu-app/psycho-list-active.svg';
import homeIcon from '../../asset/svg/menu-app/home.svg';
import homeActiveIcon from '../../asset/svg/menu-app/home-active.svg';
import moreImg from '../../asset/svg/menu-app/more.svg';
import moreActiveImg from '../../asset/svg/menu-app/moreActive.svg';

import { CONSULTATION_LIST_PAGE_PATH } from '../../page/consultation-list';
import { PSYCHO_LIST_PAGE_PATH } from '../../page/psycho-list';
import { ACCOUNT_UPDATE_PAGE_PATH } from '../../page/account-profile-update';
import { HOME_PAGE_PATH } from '../../page/landing';
import { SETTINGS_PAGE_PATH } from '../../page/settings';
import { PAYMENT_PAGE_PATH } from '../../page/payment';
import { USER_PAGE_PATH } from '../../page/user';
import { SCHEDULE_PAGE_PATH } from '../../page/schedule';
import { USER_ROLE } from '../../data/auth/constant';

export const WITHOUT_TOOLBAR_PAGE = [];
// export const ADMIN_PAGE = [WELLCOME_PAGE_PATH];

export const MODULE_NAME = 'MOBILE_MENU_MODULE_NAME';
export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const data: any = {
  user: [
    {
      img: noteIcon,
      imgActive: noteActiveIcon,
      tid: 'NAVIGATION.TAB_APP.CONSULTATION_LIST',
      path: CONSULTATION_LIST_PAGE_PATH,
    },
    {
      tid: 'NAVIGATION.TAB_APP.CARETAKER_LIST',
      img: psychoIcon,
      imgActive: psychoActiveIcon,
      path: PSYCHO_LIST_PAGE_PATH,
      isVisible: USER_ROLE.USER,
    },
    {
      tid: 'NAVIGATION.TAB_APP.SCHEDULE',
      img: calendarIcon,
      imgActive: calendarActiveIcon,
      path: '/schedule',
      isVisible: USER_ROLE.CARETAKER,
    },
    {
      tid: 'NAVIGATION.TAB_APP.ACCOUNT_UPDATE',
      img: accountIcon,
      imgActive: accountActiveIcon,
      path: '/account-update',
    },
    {
      img: moreImg,
      imgActive: moreActiveImg,
      tid: 'NAVIGATION.TAB_APP.MORE',
    },
  ],

  userMore: [
    {
      img: settingIcon,
      imgActive: settingActiveIcon,
      tid: 'NAVIGATION.TAB_APP.SETTINGS',
      path: '/nalashtuvannya',
    },
    {
      tid: 'NAVIGATION.TAB_APP.HOME',
      img: homeIcon,
      imgActive: homeActiveIcon,
      path: HOME_PAGE_PATH,
    },
  ],

  admin: [
    {
      img: noteIcon,
      imgActive: noteActiveIcon,
      tid: 'NAVIGATION.TAB_APP.CONSULTATION_LIST',
      path: CONSULTATION_LIST_PAGE_PATH,
    },
    {
      tid: 'NAVIGATION.TAB_APP.USER',
      img: userIcon,
      imgActive: userActiveIcon,
      path: USER_PAGE_PATH,
      // isVisible: userRole === USER_ROLE.ADMIN,
    },
    {
      tid: 'NAVIGATION.TAB_APP.PAYMENT',
      img: paymentIcon,
      imgActive: paymentActiveIcon,
      path: PAYMENT_PAGE_PATH,
      // isVisible: userRole === USER_ROLE.ADMIN,
    },
    {
      img: moreImg,
      imgActive: moreActiveImg,
      tid: 'NAVIGATION.TAB_APP.MORE',
    },
  ],

  adminMore: [
    {
      tid: 'NAVIGATION.TAB_APP.CARETAKER_LIST',
      img: psychoIcon,
      imgActive: psychoActiveIcon,
      path: PSYCHO_LIST_PAGE_PATH,
    },
    {
      tid: 'NAVIGATION.TAB_APP.HOME',
      img: homeIcon,
      imgActive: homeActiveIcon,
      path: HOME_PAGE_PATH,
    },
    {
      img: settingIcon,
      imgActive: settingActiveIcon,
      tid: 'NAVIGATION.TAB_APP.SETTINGS',
      path: '/nalashtuvannya',
    },
    {
      title: 'NAVIGATION.TAB_APP.NEWSLETTER',
      icon: userIcon,
      iconActive: userActiveIcon,
      path: USER_PAGE_PATH,
    },
  ],
};

export interface ACTION_RESPONSE_INTER {}

export interface DATA_INTER {}

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  messages: string[];
  statusCode: string;
}
