import { ANIMAL_TYPE_ENUM } from '../../data/animal/constant';
import {
  OPTION_INTER,
  PLACEMENT_ENUM,
  SPECIALIST_ENUM,
  TRANSPORTATION_ENUM,
} from '../../data/user/constant';
import { i18n } from '../../lib/lang';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export enum FORM_VALUE_ENUM {
  ANIMAL_TYPE_LIST = 'workAnimalType',
  SPECIALIST_LIST = 'specialistWith',
  TRANSPORTATION_LIST = 'transportation',
  PLACEMENT_LIST = 'placement',
  COMPATIBILITY_ANIMAL = 'ableCompatibilityAnimal',
  ALLOWS_WEIGHT_MORE_THIRTY = 'allowsWeightMoreThirty',
  ALLOWS_AGE_MORE_EIGHT = 'allowsAgeMoreEight',
  ALLOWS_AGGRESSIVE = 'allowsAggressive',
  ALLOWS_NAUGHTY = 'allowsNaughty',
  ALLOWS_BITE = 'allowsBite',
  ALLOWS_PHYSICAL_DISABILITY = 'allowsPhysicaldisability',
  ALLOWS_UNATTENDED_ANIMAL = 'allowsUnattendedAnimal',
}

export type FORM_VALUE_TYPE = `${FORM_VALUE_ENUM}`;

export interface FORM_VALUE_INTER {
  [FORM_VALUE_ENUM.ANIMAL_TYPE_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.SPECIALIST_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.TRANSPORTATION_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.PLACEMENT_LIST]: OPTION_INTER[];
  [FORM_VALUE_ENUM.COMPATIBILITY_ANIMAL]: string;

  [FORM_VALUE_ENUM.ALLOWS_WEIGHT_MORE_THIRTY]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.ALLOWS_AGE_MORE_EIGHT]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.ALLOWS_AGGRESSIVE]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.ALLOWS_NAUGHTY]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.ALLOWS_BITE]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.ALLOWS_PHYSICAL_DISABILITY]: OPTION_INTER | null;
  [FORM_VALUE_ENUM.ALLOWS_UNATTENDED_ANIMAL]: OPTION_INTER | null;
}

export const COMPATIBILITY_ANIMAL_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.YES'),
    value: 'true',
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.NO'),
    value: 'false',
  },
];

export const ANIMAL_TYPE_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.DOG'),
    value: ANIMAL_TYPE_ENUM.DOG,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.CAT'),
    value: ANIMAL_TYPE_ENUM.CAT,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.BIRD'),
    value: ANIMAL_TYPE_ENUM.BIRD,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.RAT'),
    value: ANIMAL_TYPE_ENUM.RAT,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.FERRET'),
    value: ANIMAL_TYPE_ENUM.FERRET,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.REPTILE'),
    value: ANIMAL_TYPE_ENUM.REPTILE,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.RABBIT'),
    value: ANIMAL_TYPE_ENUM.RABBIT,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.GUINEA_PIG'),
    value: ANIMAL_TYPE_ENUM.GUINEA_PIG,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.HAMSTER'),
    value: ANIMAL_TYPE_ENUM.HAMSTER,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.CHINCHILLA'),
    value: ANIMAL_TYPE_ENUM.CHINCHILLA,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.OTHER'),
    value: ANIMAL_TYPE_ENUM.OTHER,
  },
];

export const SPECIALIST_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.SPECIALIST.ANGRY'),
    value: SPECIALIST_ENUM.ANGRY,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.SPECIALIST.BLIND'),
    value: SPECIALIST_ENUM.BLIND,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.SPECIALIST.DEAF'),
    value: SPECIALIST_ENUM.DEAF,
  },
];
export const TRANSPORTATION_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.TRANSPORTATION.CAR'),
    value: TRANSPORTATION_ENUM.CAR,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.TRANSPORTATION.BIKE'),
    value: TRANSPORTATION_ENUM.BIKE,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.TRANSPORTATION.WALK'),
    value: TRANSPORTATION_ENUM.WALK,
  },
];
export const PLACEMENT_OPTION_LIST = [
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.PLACEMENT.OWNER'),
    value: PLACEMENT_ENUM.OWNER,
  },
  {
    label: i18n.t('USER.PSYCHOLOGIST.SELECT.PLACEMENT.CARETAKER'),
    value: PLACEMENT_ENUM.CARETAKER,
  },
];

export const API = {
  MAIN: {
    TYPE: 'PATCH',
    URL: 'user/data-update',
  },
  GET: {
    TYPE: 'GET',
    URL: 'user/psychologist-data',
  },

  DELETE: {
    TYPE: 'DELETE',
    URL: (id: string | null) => `file/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}
