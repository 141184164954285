import { ANIMAL_TYPE_ENUM } from '../../data/animal/constant';
import {
  GENDER_ENUM,
  LANGUAGE_ENUM,
  METHOD_ENUM,
  PLACEMENT_ENUM,
  REQUEST_ENUM,
  SPECIALIST_ENUM,
  TRANSPORTATION_ENUM,
} from '../../data/user/constant';
import { i18n } from '../../lib/lang';
import { CATEGORY_ENUM } from '../profile-psycho-create-category/constant';

export const MODULE_NAME = 'PROFILE_PSYCHO_UPDATE_DATA_MODULE_NAME';

export const ACTION_TYPE = {
  REQUEST_PENDING: `${MODULE_NAME}.REQUEST_PENDING`,
};

export const API = {
  GET: {
    TYPE: 'GET',
    URL: (id?: string) => `user/psycho/info/${id}`,
  },
};

export interface ACTION_ERROR_INTER {
  error: string;
  message: string;
  statusCode: string;
}

interface VIDEO_INTER {
  id: string;
  name: string;
  url: string;
}

interface CERTIFICATE_INTER {
  id: string;
  name: string;
  url: string;
}

interface PHOTO_INTER {
  id: string;
  name: string;
  url: string;
}

export interface CATEGORY_RAW_INTER {
  id: string;
  category: CATEGORY_ENUM;
  createDate: string;
  description: string;
  updateDate: string;
  certificate: CERTIFICATE_INTER[];
  price: number;
  duration: string | number;
  requireHours: number;
  overNightAvailability: boolean;
  fullDayAvailability: boolean;
}

export interface DATA_RAW_INTER {
  name: string;
  lastName: string;
  phone: string;
  about: string;
  duration: string | number;
  age: number;
  gender: GENDER_ENUM;
  city: string;
  category: CATEGORY_RAW_INTER[];
  certificate: CERTIFICATE_INTER[];
  createDate: string;
  description: string;
  id: string;
  country: string;
  language: LANGUAGE_ENUM[];
  updateDate: string;
  methodCustom: string;
  methodList: METHOD_ENUM[];
  needAge: number | null;
  needGender: number | null;
  photo: PHOTO_INTER;
  requestCustom: string;
  requestList: REQUEST_ENUM[];
  timezone: number;
  videoIntroduction: VIDEO_INTER;
  transportation: TRANSPORTATION_ENUM[];
  aboutPerson: string;
  myPath: string;
  aboutMyService: string;
  yearExperience: number;
  workAnimalType: ANIMAL_TYPE_ENUM[];
  placement: PLACEMENT_ENUM[];
  workingMethods: string;
  medicationExperience: string;
  psychoCategory: CATEGORY_ENUM[];
  ableCompatibilityAnimal: boolean;
  requireHours: number;
  specialistWith: SPECIALIST_ENUM[];
}

export interface CATEGORY_INTER extends Omit<CATEGORY_RAW_INTER, 'category'> {
  category: string;
}

export interface DATA_INTER
  extends Omit<
    DATA_RAW_INTER,
    | 'methodList'
    | 'category'
    | 'requestList'
    | 'gender'
    | 'timezone'
    | 'age'
    | 'city'
    | 'country'
    | 'nationality'
    | 'language'
    | 'transportation'
    | 'requireHours'
    | 'yearExperience'
    | 'hoursExperience'
    | 'placement'
    | 'requireHours'
    | 'ableCompatibilityAnimal'
    | 'specialistWith'
    | 'workAnimalType'
    | 'transportation'
  > {
  methodList: string[];
  category: CATEGORY_INTER[];
  requestList: string[];
  gender: string;
  timezone: string;
  age: string;
  city: string;
  country: string;
  language: string[];
  yearExperience: number;
  placement: string[];
  requireHours: number;
  ableCompatibilityAnimal: string;
  specialistWith: string[];
  workAnimalType: string[];
  transportation: string[];
}

export const REQUEST_VIEW = {
  [REQUEST_ENUM.ANXIETY]: i18n.t('USER.USER.SELECT.REQUEST.ANXIETY'),
  [REQUEST_ENUM.FAMILY_CONFLICT]: i18n.t(
    'USER.USER.SELECT.REQUEST.FAMILY_CONFLICT',
  ),
  [REQUEST_ENUM.STRESS]: i18n.t('USER.USER.SELECT.REQUEST.STRESS'),
};

export const METHOD_VIEW = {
  [METHOD_ENUM.COGNITIVE_BEHAVIORAL_THERAPY]: i18n.t(
    'USER.USER.SELECT.METHOD.COGNITIVE_BEHAVIORAL_THERAPY',
  ),
  [METHOD_ENUM.GESTALTTHERAPY]: i18n.t(
    'USER.USER.SELECT.METHOD.GESTALTTHERAPY',
  ),
  [METHOD_ENUM.PSYCHOANALYSIS]: i18n.t(
    'USER.USER.SELECT.METHOD.PSYCHOANALYSIS',
  ),
};

export const PLACEMENT_VIEW = {
  [PLACEMENT_ENUM.OWNER]: i18n.t('USER.PSYCHOLOGIST.SELECT.PLACEMENT.OWNER'),
  [PLACEMENT_ENUM.CARETAKER]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.PLACEMENT.CARETAKER',
  ),
};

export const TRANSPORTATION_VIEW = {
  [TRANSPORTATION_ENUM.BIKE]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.TRANSPORTATION.CAR',
  ),
  [TRANSPORTATION_ENUM.CAR]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.TRANSPORTATION.BIKE',
  ),
  [TRANSPORTATION_ENUM.WALK]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.TRANSPORTATION.WALK',
  ),
};

export const GENDER_VIEW = {
  [GENDER_ENUM.MALE]: i18n.t('USER.PSYCHOLOGIST.SELECT.MALE'),
  [GENDER_ENUM.FEMALE]: i18n.t('USER.PSYCHOLOGIST.SELECT.FEMALE'),
};

export const ANIMALTYPE_VIEW = {
  [ANIMAL_TYPE_ENUM.DOG]: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.DOG'),
  [ANIMAL_TYPE_ENUM.CAT]: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.CAT'),
  [ANIMAL_TYPE_ENUM.BIRD]: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.BIRD'),
  [ANIMAL_TYPE_ENUM.RAT]: i18n.t('USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.RAT'),
  [ANIMAL_TYPE_ENUM.FERRET]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.FERRET',
  ),
  [ANIMAL_TYPE_ENUM.REPTILE]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.REPTILE',
  ),
  [ANIMAL_TYPE_ENUM.RABBIT]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.RABBIT',
  ),
  [ANIMAL_TYPE_ENUM.GUINEA_PIG]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.GUINEA_PIG',
  ),
  [ANIMAL_TYPE_ENUM.HAMSTER]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.HAMSTER',
  ),
  [ANIMAL_TYPE_ENUM.CHINCHILLA]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.CHINCHILLA',
  ),
  [ANIMAL_TYPE_ENUM.OTHER]: i18n.t(
    'USER.PSYCHOLOGIST.SELECT.ANIMAL_TYPE.OTHER',
  ),
};

export const SPECIALIST_VIEW = {
  [SPECIALIST_ENUM.ANGRY]: i18n.t('USER.PSYCHOLOGIST.SELECT.SPECIALIST.ANGRY'),
  [SPECIALIST_ENUM.BLIND]: i18n.t('USER.PSYCHOLOGIST.SELECT.SPECIALIST.BLIND'),
  [SPECIALIST_ENUM.DEAF]: i18n.t('USER.PSYCHOLOGIST.SELECT.SPECIALIST.DEAF'),
};

export const LANGUAGE_VIEW: Record<LANGUAGE_ENUM, string> = {
  [LANGUAGE_ENUM.UA]: i18n.t('USER.USER.SELECT.LANGUAGE.UA'),
  [LANGUAGE_ENUM.POL]: i18n.t('USER.USER.SELECT.LANGUAGE.POL'),
  [LANGUAGE_ENUM.ENG]: i18n.t('USER.USER.SELECT.LANGUAGE.ENG'),
};

export const CATEGORY_VIEW = {
  [CATEGORY_ENUM.WALK]: i18n.t('USER.PSYCHOLOGIST.SELECT.CATEGORY.WALK'),
  [CATEGORY_ENUM.FEED_CLEAN]: i18n.t('USER.PSYCHOLOGIST.SELECT.CATEGORY.FEED'),
  [CATEGORY_ENUM.HOLD]: i18n.t('USER.PSYCHOLOGIST.SELECT.CATEGORY.HOLD'),
};
