import { OPTION_INTER } from '../../data/user/constant';

const toBoolean = (value: string | null | undefined): boolean | undefined => {
  if (value === 'true') return true;
  if (value === 'false') return false;
  return undefined;
};

export const convert = (values: any) => {
  return {
    ...values,
    workAnimalType: values?.workAnimalType.map((e: OPTION_INTER) => e.value),
    specialistWith: values?.specialistWith.map((e: OPTION_INTER) => e.value),
    transportation: values?.transportation.map((e: OPTION_INTER) => e.value),
    placement: values?.placement.map((e: OPTION_INTER) => e.value),
    ableCompatibilityAnimal: toBoolean(values?.ableCompatibilityAnimal),
    allowsWeightMoreThirty: toBoolean(values?.allowsWeightMoreThirty),
    allowsAgeMoreEight: toBoolean(values?.allowsAgeMoreEight),
    allowsAggressive: toBoolean(values?.allowsAggressive),
    allowsNaughty: toBoolean(values?.allowsNaughty),
    allowsBite: toBoolean(values?.allowsBite),
    allowsPhysicaldisability: toBoolean(values?.allowsPhysicaldisability),
    allowsUnattendedAnimal: toBoolean(values?.allowsUnattendedAnimal),
  };
};
