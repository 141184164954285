import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import { FileUploaderElem } from '../../common/file-uploader';
import { AlertActionElem } from '../../common/alert-action';
import { ButtonElem } from '../../common/button';
import { FieldTextElem } from '../../common/field-text';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { FormElem } from '../../common/form';
import { SelectElem } from '../../common/select';
import { TextElem } from '../../common/text';
import { Spacing } from '../../theme';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import {
  FORM_VALUE_ENUM,
  CATEGORY_OPTION_LIST,
  FORM_VALUE_TYPE,
  SERVICE_TYPE_OPTION_LIST,
  OVER_NIGHT_AVAILABILITY_OPTION_LIST,
  FULL_DAY_AVAILABILITY_OPTION_LIST,
  CATEGORY_ENUM,
} from './constant';
import { LoaderElem } from '../../common/loader';
import { YES_NO_OPTION_LIST } from '../profile-user-update-data/constant';

export const Component: React.FC<{
  onToggle: () => void;
  isOpen: boolean;
  isLoading?: boolean;
  // setVideo: React.Dispatch<React.SetStateAction<File | null>>;
  // setDiplomaState: Function;
  // setAddDiplomaCount: React.Dispatch<React.SetStateAction<number>>;
  // setCertificateState: Function;
  // addCertificateCount: number;
  // setAddCertificateCount: React.Dispatch<React.SetStateAction<number>>;
  formik: FormikValues;
  getFieldValue: Function;
  getFieldError: Function;
  isFieldError: (name: FORM_VALUE_TYPE) => boolean;
  handleSubmit: Function;
  isSubmitDisabled?: boolean;
  isError?: boolean;
  getErrorMessage: Function;
  isSuccess?: boolean;
  setFieldValue: Function;
  setValue: Function;
  // addDiplomaCount: number;
  // onDeleteDiploma: Function;
  // onDeleteCertificate: Function;
  // onDeleteVideo: Function;
  // isAddDiplomaDisabled: boolean;
  // isAddCertificateDisabled: boolean;
}> = ({
  onToggle,
  isOpen,
  isLoading,
  // setVideo,
  // setDiplomaState,
  // setAddDiplomaCount,
  // setCertificateState,
  // addCertificateCount,
  // setAddCertificateCount,
  formik,
  getFieldValue,
  getFieldError,
  isFieldError,
  handleSubmit,
  isSubmitDisabled,
  isError,
  getErrorMessage,
  isSuccess,
  setFieldValue,
  setValue,
  // addDiplomaCount,
  // onDeleteDiploma,
  // onDeleteCertificate,
  // onDeleteVideo,
  // isAddDiplomaDisabled,
  // isAddCertificateDisabled,
}) => {
  return (
    <>
      {isLoading && <LoaderElem />}

      {isOpen && (
        <Container onSubmit={() => {}}>
          <SelectElem
            isDot
            placeholder="USER.PSYCHOLOGIST.SERVICE_PLACEHOLDER"
            name={FORM_VALUE_ENUM.CATEGORY}
            onChange={setFieldValue}
            options={CATEGORY_OPTION_LIST}
            title="USER.PSYCHOLOGIST.SERVICE"
            errorMessage={getFieldError(FORM_VALUE_ENUM.CATEGORY)}
            error={isFieldError(FORM_VALUE_ENUM.CATEGORY)}
            value={setValue(CATEGORY_OPTION_LIST, FORM_VALUE_ENUM.CATEGORY)}
          />

          <FieldTextElem
            isDot
            name={FORM_VALUE_ENUM.PRICE}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onWheel={(e: any) => e.target.blur()}
            title="USER.PSYCHOLOGIST.PRICE"
            placeholder="USER.PSYCHOLOGIST.PRICE_PLACEHOLDER"
            value={getFieldValue(FORM_VALUE_ENUM.PRICE)}
            errorMessage={getFieldError(FORM_VALUE_ENUM.PRICE)}
            error={isFieldError(FORM_VALUE_ENUM.PRICE)}
            type="number"
          />

          {getFieldValue(FORM_VALUE_ENUM.CATEGORY) === CATEGORY_ENUM.HOLD && (
            <SelectElem
              isDot
              placeholder="USER.PSYCHOLOGIST.IS_OWNER_HOME_PLACEHOLDER"
              name={FORM_VALUE_ENUM.IS_OWNER_HOME}
              onChange={setFieldValue}
              options={YES_NO_OPTION_LIST}
              title="USER.PSYCHOLOGIST.IS_OWNER_HOME"
              errorMessage={getFieldError(FORM_VALUE_ENUM.IS_OWNER_HOME)}
              error={isFieldError(FORM_VALUE_ENUM.IS_OWNER_HOME)}
              value={setValue(
                YES_NO_OPTION_LIST,
                FORM_VALUE_ENUM.IS_OWNER_HOME,
              )}
            />
          )}

          {getFieldValue(FORM_VALUE_ENUM.IS_OWNER_HOME) === 'true' && (
            <FieldTextElem
              isDot
              name={FORM_VALUE_ENUM.HOLD_PRICE_OWNER_HOME}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onWheel={(e: any) => e.target.blur()}
              title="USER.PSYCHOLOGIST.HOLD_PRICE_OWNER_HOME"
              placeholder="USER.PSYCHOLOGIST.HOLD_PRICE_OWNER_HOME_PLACEHOLDER"
              value={getFieldValue(FORM_VALUE_ENUM.HOLD_PRICE_OWNER_HOME)}
              errorMessage={getFieldError(
                FORM_VALUE_ENUM.HOLD_PRICE_OWNER_HOME,
              )}
              error={isFieldError(FORM_VALUE_ENUM.HOLD_PRICE_OWNER_HOME)}
              type="number"
            />
          )}

          {/* <FileUploaderElem
            title="COMMON.FILE.VIDEO_TITLE"
            onFileUpload={(e) => {
              setVideo(e.file);
            }}
            onRemove={onDeleteVideo}
            uploadType="video"
            dropZoneTitle="COMMON.FILE.VIDEO_CONTENT_TITLE"
          /> */}
          {/* <FileButtonContainer>
            {Array.from({ length: addDiplomaCount }).map((_, index) => (
              <FileUploaderElem
                isDot={index === 0 ? true : false}
                title={index === 0 ? 'COMMON.FILE.DIPLOMA_TITLE' : ''}
                key={index}
                onFileUpload={(e) => {
                  setDiplomaState(e);
                }}
                onRemove={onDeleteDiploma}
                uploadType="document"
                dropZoneTitle="COMMON.FILE.DIPLOMA_CONTENT_TITLE"
              />
            ))}
            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              disabled={isAddDiplomaDisabled || addDiplomaCount >= 6}
              type="file"
              onClick={() => {
                if (addDiplomaCount < 6) {
                  setAddDiplomaCount((prev) => prev + 1);
                }
              }}
            />
          </FileButtonContainer> */}
          {/* 
          <FileButtonContainer>
            {Array.from({ length: addCertificateCount }).map((_, index) => (
              <FileUploaderElem
                isDot={index === 0 ? true : false}
                title={index === 0 ? 'COMMON.FILE.CERTIFICATE_TITLE' : ''}
                key={index}
                onFileUpload={(e) => {
                  setCertificateState(e);
                }}
                onRemove={onDeleteCertificate}
                uploadType="document"
                dropZoneTitle="COMMON.FILE.CERTIFICATE_CONTENT_TITLE"
              />
            ))}
            <ButtonElem
              tid="COMMON.FILE.UPLOAD"
              disabled={isAddCertificateDisabled || addCertificateCount >= 6}
              type="file"
              onClick={() => {
                if (addCertificateCount < 6) {
                  setAddCertificateCount((prev) => prev + 1);
                }
              }}
            />
          </FileButtonContainer> */}

          <TitleContainer>
            <Dot />
            <TextElem
              tid="USER.PSYCHOLOGIST.REQUIRED"
              type="light"
              size="input"
              color="textSecondary"
            />
          </TitleContainer>
          <ButtonElem
            onClick={handleSubmit}
            type="submit"
            disabled={isSubmitDisabled}
            tid="CATEGORY.FORM.BUTTON"
          />
          {isError && <AlertActionElem text={getErrorMessage()} />}
          {isSuccess && (
            <AlertActionElem type="success" tid="CATEGORY.FORM.SUCCESS" />
          )}
        </Container>
      )}
    </>
  );
};

const FileButtonContainer = styled.div`
  display: grid;
  grid-gap: ${Spacing(3)};
  width: 100%;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
  align-self: start;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;

const Container = styled(FormElem)`
  display: flex;
  flex-direction: column;
  gap: ${Spacing(4)};
  background-color: ${({ theme }) => theme[COLOR_ENUM.WHITE]};
  padding: ${Spacing(7)};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CONTENT]}px;
`;
