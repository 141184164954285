import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  ACTION_ERROR_INTER,
  FORM_VALUE_ENUM,
  FORM_VALUE_INTER,
  FORM_VALUE_TYPE,
  MODULE_NAME,
  MODULE_NAME_CERTIFICATE,
} from './constant';
import { Component } from './component';
import {
  getAction,
  getActionCertificate,
  removeAction,
  action as fetch,
} from './action';
import { SELECT_OPTION_ITEM_DATA } from '../../common/select/index';
import { FormikValues, useFormik } from 'formik';
import {
  PSYCHOLOGIST_ITEM_DATA_INTER,
  PSYCHOLOGIST_ITEM_DATA_RAW_INTER,
} from '../../data/user/constant';
import { convertPsychologist } from '../../data/user/convert';
import { validation } from '../../lib/validation/core';
import { useDispatch } from '../../lib/store/index';
import { convert } from './convert';

const config = {
  [FORM_VALUE_ENUM.YEAR_EXPERIENCE]: [],
  [FORM_VALUE_ENUM.PROFILE_DESCRIPTION]: [],
  [FORM_VALUE_ENUM.ABOUT_PERSON]: [],
};

const validate = (values: FormikValues) => validation(values, config);

export const Container: React.FC = () => {
  const [visibleCertificate, setVisibleCertificate] = useState<number>(0);
  // const [visibleDiploma, setVisibleDiploma] = useState<number>(0);

  const query = useQueryClient();
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState<any>({});

  const action = useMutation((d: FORM_VALUE_INTER) => dispatch(fetch(d)), {
    onSuccess: () => {
      return query.invalidateQueries(MODULE_NAME);
    },
  });

  const preFetch = useQuery(MODULE_NAME, () => getAction());

  const preFetchCertificate = useQuery<any>(
    MODULE_NAME_CERTIFICATE,
    getActionCertificate,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        // @ts-ignore
        // if (data?.diploma?.length < 1) {
        //   setVisibleDiploma(1);
        // } else {
        //   setVisibleDiploma(0);
        // }
        // @ts-ignore
        if (data?.certificate?.length < 1) {
          setVisibleCertificate(1);
        } else {
          setVisibleCertificate(0);
        }
      },
    },
  );
  const {
    data: preFetchDataCertificate,
    isSuccess: preFetchSuccessCartificate,
    isLoading: preFetchIsLoadingCertificate,
    refetch: refetchCertificate,
    isRefetching: isCertificateRefetching,
    error: certificateError,
    isError: isCertificateError,
  } = preFetchCertificate;

  const getData = async (): Promise<
    PSYCHOLOGIST_ITEM_DATA_INTER | undefined
  > => {
    const data =
      (await preFetch.data) as unknown as PSYCHOLOGIST_ITEM_DATA_RAW_INTER;

    if (data) {
      return convertPsychologist(data);
    }
  };

  const fetchData = async () => {
    // const offsetInMinutes = new Date().getTimezoneOffset();
    // const offsetInHours = -offsetInMinutes / 60;

    if (preFetch.data) {
      const data = await getData();

      if (preFetch.isSuccess && data) {
        setInitialValues({
          [FORM_VALUE_ENUM.YEAR_EXPERIENCE]: data.yearExperience || null,
          [FORM_VALUE_ENUM.PROFILE_DESCRIPTION]: data.profileDescription || '',
          [FORM_VALUE_ENUM.ABOUT_PERSON]: data.aboutPerson || '',
        });
      }
    }
  };

  useEffect(() => {
    fetchData();
    if (action.isSuccess) {
      formik.resetForm();
    }
  }, [preFetch.isSuccess, action.isSuccess]);

  const formik: FormikValues = useFormik({
    initialValues,
    enableReinitialize: true,
    validate,
    onSubmit: (values: FORM_VALUE_INTER) => {
      const convertedValues = convert(values);

      return action.mutate(convertedValues);
    },
  });

  // useEffect(() => {
  //   formik.setValues(initialValues); // почему-то по другому формик не инициализирует значения, даже если data есть // переделать с использованием enableReinitialize
  // }, [initialValues]);

  const isFieldError = (name: FORM_VALUE_TYPE): boolean => {
    return formik.errors[name] && formik.touched[name] ? true : false;
  };

  const getFieldError = (name: FORM_VALUE_TYPE): string | undefined =>
    isFieldError(name) ? formik.errors[name] : undefined;

  const isSubmitDisabled = () => {
    if (!formik.isValid) {
      return true;
    }
    if (!formik.dirty) {
      return true;
    }

    if (action.isLoading || preFetch.isLoading) {
      return true;
    }
  };

  const isLoading = () => {
    if (preFetch.isLoading) {
      return true;
    }
  };

  const isSuccess = () => {
    if (preFetch.isSuccess) {
      return true;
    }
  };

  const isError = () => {
    if (action.isError && !action.isLoading && getErrorMessage()) {
      return true;
    }
    if (preFetch.isError && !preFetch.isLoading && getErrorMessage()) {
      return true;
    }
    if (
      isCertificateError &&
      !preFetchIsLoadingCertificate &&
      getErrorMessage()
    ) {
      return true;
    }
  };

  const getFieldValue = (name: FORM_VALUE_TYPE) => formik.values[name];

  const setFieldValue = (name: FORM_VALUE_TYPE, e: SELECT_OPTION_ITEM_DATA) =>
    formik.setFieldValue(name, e.value);

  const setValue = (options: SELECT_OPTION_ITEM_DATA[], value: any) => {
    if (typeof formik.values[value] == 'string') {
      const optionList = options?.filter(
        (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
      )[0];
      return optionList ? optionList : null;
    }
    const optionList = options?.filter(
      (item: SELECT_OPTION_ITEM_DATA) => item.value == formik.values[value],
    )[0];
    return optionList ? optionList : null;
  };

  const onChangeSelect = (name: string, values: any) => {
    formik.setFieldValue(name, [...values]);
  };

  const onRefetch = () => {
    refetchCertificate();
  };

  const deleteAction = useMutation((id: string | null) => removeAction(id), {
    onSuccess: () => {
      refetchCertificate();
      query.invalidateQueries(MODULE_NAME_CERTIFICATE);
    },
  });

  const handleDelete = (id: string) => {
    deleteAction.mutate(id);
  };
  const onDelete = () => {
    if (preFetchDataCertificate?.videoIntroduction?.id) {
      const id = preFetchDataCertificate.videoIntroduction?.id;
      return deleteAction.mutate(id);
    }
  };

  const isCertificateUploading =
    deleteAction.isLoading || isCertificateRefetching;

  const getErrorMessage = () => {
    const error: ACTION_ERROR_INTER = action.error as ACTION_ERROR_INTER;
    const preFetchError: ACTION_ERROR_INTER =
      action.error as ACTION_ERROR_INTER;
    const errorObj = certificateError as ACTION_ERROR_INTER;

    if (certificateError) {
      return errorObj?.message;
    }
    if (error) {
      return error.message;
    }
    if (preFetchError) {
      return preFetchError.message;
    }
  };

  //@ts-ignore
  // const diplomaLength = 6 - Number(preFetchData?.diploma?.length); // max count of
  //@ts-ignore
  const certificateLength =
    6 - Number(preFetchDataCertificate?.certificate?.length); // max count of
  const isVisibleCertificateCount = visibleCertificate < certificateLength;
  // const isVisibleDiplomaCount = visibleDiploma < diplomaLength;

  const handleAddCertificate = () => {
    if (isVisibleCertificateCount) {
      setVisibleCertificate((prev) => prev + 1);
    }
  };

  const isDisabledCertificate =
    !isVisibleCertificateCount ||
    preFetchIsLoadingCertificate ||
    isCertificateUploading ||
    visibleCertificate >= 1;

  return (
    <Component
      handleAddCertificate={handleAddCertificate}
      // handleAddDiploma={handleAddDiploma}
      // isDisabledDiploma={isDisabledDiploma}
      isDisabledCertificate={isDisabledCertificate}
      onRefetch={onRefetch}
      deleteAction={onDelete}
      dataCertificate={preFetchDataCertificate}
      preFetchSuccessCeritificate={preFetchSuccessCartificate}
      handleDelete={handleDelete}
      isSuccessDelete={deleteAction.isSuccess}
      isCertificateUploading={isCertificateUploading}
      isCertificateLoading={preFetchIsLoadingCertificate}
      isCertificateError={isCertificateError}
      onChangeSelect={onChangeSelect}
      isFieldError={isFieldError}
      getFieldError={getFieldError}
      isSubmitDisabled={isSubmitDisabled}
      getFieldValue={getFieldValue}
      formik={formik}
      setFieldValue={setFieldValue}
      setValue={setValue}
      isLoadingAction={action.isLoading}
      isSuccessAction={action.isSuccess}
      isLoading={isLoading()}
      isSuccess={isSuccess()}
      isError={isError()}
      errorMessage={getErrorMessage()}
      visibleCertificate={visibleCertificate}
      // visibleDiploma={visibleDiploma}
    />
  );
};
