import React from 'react';
import styled from 'styled-components';
import { FormikValues } from 'formik';

import {
  FORM_VALUE_ENUM,
  GENDER_OPTION_LIST,
  ISFOP_OPTION_LIST,
  LANGUAGE_OPTION_LIST,
  YES_NO_OPTION_LIST,
} from './constant';

import { TextElem } from '../../common/text';
import { GridElem } from '../../common/grid';
import { FieldTextElem } from '../../common/field-text';
import { ButtonElem } from '../../common/button';
import { LoaderElem } from '../../common/loader';
import { AlertActionElem } from '../../common/alert-action';
import { FormElem } from '../../common/form';
import { ContentContainerElem } from '../../common/content-container';
import { DoubleContainerElem } from '../../common/double-container';
import { SelectElem } from '../../common/select';
import { TIMEZONE_OPTION_LIST } from '../../data/user/timezone';
import { FieldTextAreaElem } from '../../common/field-text-area';
import { COLOR_ENUM } from '../../theme/color';
import {
  SIZE_BORDER_RADIUS_DATA,
  SIZE_BORDER_RADIUS_ENUM,
} from '../../theme/size';
import { Spacing } from '../../theme';
import { SkeletonFormElem } from '../../common/skeleton-form';
import { FileItemUploadContainer } from '../file-item-upload/index';
import { CITY_OPTION_LIST } from '../psycho-filter/constant';

export const Component: React.FC<{
  formik: FormikValues;
  isFieldError: Function;
  getFieldError: Function;
  isSubmitDisabled: Function;
  getFieldValue: Function;
  isLoading?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  errorMessage?: string;
  setFieldValue?: Function;
  setValue?: Function;
  onChangeSelect: (name: string, values: any) => void;
  isLoadingAction: boolean;
  isSuccessAction: boolean;
  // deleteAction: Function;
  // onRefetch: Function;
  // photoUrl?: string;
}> = ({
  formik,
  isFieldError,
  getFieldError,
  isSubmitDisabled,
  getFieldValue,
  isLoading,
  isSuccess,
  isError,
  errorMessage,
  setValue = () => {},
  setFieldValue,
  isLoadingAction,
  isSuccessAction,
  onChangeSelect,
  // deleteAction,
  // onRefetch,
  // photoUrl,
}) => {
  const multiValueContainer = ({ selectProps, data }: any) => {
    const label = data.label;
    const allSelected = selectProps.value;
    const index = allSelected.findIndex(
      (selected: any) => selected.label === label,
    );
    const isLastSelected = index === allSelected?.length - 1;
    const labelSuffix = isLastSelected ? '' : ', ';
    const val = `${label}${labelSuffix}`;
    return val;
  };
  return (
    <>
      <FormElem gap={7} onSubmit={formik.handleSubmit}>
        {isLoadingAction && <LoaderElem />}
        {isLoading && <SkeletonFormElem />}

        {isSuccess && (
          <>
            {/* <FileContainer>
              <TextElem
                tid="USER.USER.PHOTO_FROFILE"
                type="medium"
                size="main"
                color="buttonText"
              />
              <FileItemUploadContainer
                type="photo"
                defaultValue={photoUrl}
                onSuccess={onRefetch}
                onReset={() => {
                  deleteAction();
                  onRefetch();
                }}
                isDot
                title="COMMON.FILE.IMAGE_TITLE"
              />
            </FileContainer> */}
            <ContentContainerElem>
              <GridElem spacing={4}>
                <TextElem
                  tid="USER.PSYCHOLOGIST.INFORMATION"
                  type="medium"
                  size="main"
                  color="buttonText"
                />
                <DoubleContainerElem>
                  <FieldTextElem
                    isDot
                    name={FORM_VALUE_ENUM.NAME}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="USER.PSYCHOLOGIST.NAME"
                    placeholder="USER.PSYCHOLOGIST.NAME_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.NAME)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.NAME)}
                    error={isFieldError(FORM_VALUE_ENUM.NAME)}
                  />
                  <FieldTextElem
                    isDot
                    type="number"
                    name={FORM_VALUE_ENUM.AGE}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onWheel={(e: any) => e.target.blur()}
                    title="USER.PSYCHOLOGIST.AGE"
                    placeholder="USER.PSYCHOLOGIST.AGE_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.AGE)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.AGE)}
                    error={isFieldError(FORM_VALUE_ENUM.AGE)}
                  />
                </DoubleContainerElem>
                <DoubleContainerElem>
                  <SelectElem
                    placeholder="USER.PSYCHOLOGIST.GENDER_PLACEHOLDER"
                    name={FORM_VALUE_ENUM.GENDER}
                    onChange={setFieldValue}
                    options={GENDER_OPTION_LIST}
                    title="USER.PSYCHOLOGIST.GENDER"
                    errorMessage={getFieldError(FORM_VALUE_ENUM.GENDER)}
                    error={isFieldError(FORM_VALUE_ENUM.GENDER)}
                    value={setValue(GENDER_OPTION_LIST, FORM_VALUE_ENUM.GENDER)}
                  />
                  <SelectElem
                    isDot
                    name={FORM_VALUE_ENUM.TIMEZONE}
                    onChange={setFieldValue}
                    options={TIMEZONE_OPTION_LIST}
                    dynamic
                    title="USER.PSYCHOLOGIST.TIMEZONE"
                    placeholder="USER.PSYCHOLOGIST.TIMEZONE_PLACEHOLDER"
                    errorMessage={getFieldError(FORM_VALUE_ENUM.TIMEZONE)}
                    error={isFieldError(FORM_VALUE_ENUM.TIMEZONE)}
                    value={setValue(
                      TIMEZONE_OPTION_LIST,
                      FORM_VALUE_ENUM.TIMEZONE,
                    )}
                  />
                </DoubleContainerElem>
                {/* <FieldTextAreaElem
                isDot
                name={FORM_VALUE_ENUM.ABOUT_ME}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={getFieldValue(FORM_VALUE_ENUM.ABOUT_ME)}
                title="USER.PSYCHOLOGIST.ABOUT_ME"
                placeholder="USER.PSYCHOLOGIST.ABOUT_ME_PLACEHOLDER"
                errorMessage={getFieldError(FORM_VALUE_ENUM.ABOUT_ME)}
                error={isFieldError(FORM_VALUE_ENUM.ABOUT_ME)}
              /> */}
                <DoubleContainerElem>
                  <FieldTextElem
                    isDot
                    type="phone"
                    name={FORM_VALUE_ENUM.PHONE}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="USER.PSYCHOLOGIST.PHONE"
                    placeholder="USER.PSYCHOLOGIST.PHONE_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.PHONE)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.PHONE)}
                    error={isFieldError(FORM_VALUE_ENUM.PHONE)}
                  />
                  <SelectElem
                    isDot
                    name={FORM_VALUE_ENUM.LANGUAGE}
                    onChange={onChangeSelect}
                    options={LANGUAGE_OPTION_LIST}
                    // dynamic
                    isMulti
                    title="USER.PSYCHOLOGIST.LANGUAGE"
                    placeholder="USER.PSYCHOLOGIST.LANGUAGE_PLACEHOLDER"
                    errorMessage={getFieldError(FORM_VALUE_ENUM.LANGUAGE)}
                    error={isFieldError(FORM_VALUE_ENUM.LANGUAGE)}
                    value={getFieldValue(FORM_VALUE_ENUM.LANGUAGE)}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    customComponents={{
                      MultiValueContainer: multiValueContainer,
                    }}
                  />
                </DoubleContainerElem>

                <DoubleContainerElem>
                  {/* <FieldTextElem
                    isDot
                    name={FORM_VALUE_ENUM.CITY}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="USER.PSYCHOLOGIST.CITY"
                    placeholder="USER.PSYCHOLOGIST.CITY_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.CITY)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.CITY)}
                    error={isFieldError(FORM_VALUE_ENUM.CITY)}
                  /> */}
                  <SelectElem
                    isDot
                    name={FORM_VALUE_ENUM.CITY}
                    onChange={setFieldValue}
                    title="USER.PSYCHOLOGIST.CITY"
                    placeholder="USER.PSYCHOLOGIST.CITY_PLACEHOLDER"
                    value={setValue(CITY_OPTION_LIST, FORM_VALUE_ENUM.CITY)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.CITY)}
                    error={isFieldError(FORM_VALUE_ENUM.CITY)}
                    options={CITY_OPTION_LIST}
                  />
                  <FieldTextElem
                    isDot
                    name={FORM_VALUE_ENUM.COUNTRY}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="USER.PSYCHOLOGIST.COUNTRY"
                    placeholder="USER.PSYCHOLOGIST.COUNTRY_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.COUNTRY)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.COUNTRY)}
                    error={isFieldError(FORM_VALUE_ENUM.COUNTRY)}
                  />
                </DoubleContainerElem>

                <DoubleContainerElem>
                  <FieldTextElem
                    name={FORM_VALUE_ENUM.BANK_NAME}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    title="USER.PSYCHOLOGIST.BANK_NAME"
                    placeholder="USER.PSYCHOLOGIST.BANK_NAME_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.BANK_NAME)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.BANK_NAME)}
                    error={isFieldError(FORM_VALUE_ENUM.BANK_NAME)}
                  />
                  <FieldTextElem
                    isDot
                    type="number"
                    name={FORM_VALUE_ENUM.BANK_NUMBER}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onWheel={(e: any) => e.target.blur()}
                    title="USER.PSYCHOLOGIST.BANK_NUMBER"
                    placeholder="USER.PSYCHOLOGIST.BANK_NUMBER_PLACEHOLDER"
                    value={getFieldValue(FORM_VALUE_ENUM.BANK_NUMBER)}
                    errorMessage={getFieldError(FORM_VALUE_ENUM.BANK_NUMBER)}
                    error={isFieldError(FORM_VALUE_ENUM.BANK_NUMBER)}
                  />
                </DoubleContainerElem>
                <SelectElem
                  name={FORM_VALUE_ENUM.ISFOP}
                  onChange={setFieldValue}
                  options={YES_NO_OPTION_LIST}
                  // dynamic
                  title="USER.PSYCHOLOGIST.ISFOP"
                  placeholder="USER.PSYCHOLOGIST.ISFOP_PLACEHOLDER"
                  errorMessage={getFieldError(FORM_VALUE_ENUM.ISFOP)}
                  error={isFieldError(FORM_VALUE_ENUM.ISFOP)}
                  value={setValue(YES_NO_OPTION_LIST, FORM_VALUE_ENUM.ISFOP)}
                />
                <FieldTextElem
                  name={FORM_VALUE_ENUM.DISTRICT}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  title="USER.USER.DISTRICT"
                  placeholder="USER.USER.DISTRICT_PLACEHOLDER"
                  value={getFieldValue(FORM_VALUE_ENUM.DISTRICT)}
                  errorMessage={getFieldError(FORM_VALUE_ENUM.DISTRICT)}
                  error={isFieldError(FORM_VALUE_ENUM.DISTRICT)}
                />
                <TitleContainer>
                  <Dot />
                  <TextElem
                    tid="USER.PSYCHOLOGIST.REQUIRED"
                    type="light"
                    size="input"
                    color="textSecondary"
                  />
                </TitleContainer>

                <ButtonElem
                  disabled={isSubmitDisabled()}
                  type="submit"
                  tid="CATEGORY.DATA.BUTTON"
                />
                {isError && <AlertActionElem text={errorMessage} />}
                {isSuccessAction && (
                  <AlertActionElem type="success" tid="CATEGORY.DATA.SUCCESS" />
                )}
              </GridElem>
            </ContentContainerElem>
          </>
        )}
      </FormElem>
    </>
  );
};

// const DoubleContainerStyled = styled(DoubleContainerElem)`
//   @media screen and (max-width: 768px) {
//     grid-template-columns: 1fr;
//   }
// `;

const TitleContainer = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  margin: ${Spacing(2)} 0;
`;

const Dot = styled.div`
  height: 6px;
  width: 6px;
  background-color: ${({ theme }) => theme[COLOR_ENUM.DEFAULT]};
  border-radius: ${SIZE_BORDER_RADIUS_DATA[SIZE_BORDER_RADIUS_ENUM.CIRCLE]}px;
  position: relative;
  top: 1px;
`;
